import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Plyo Pushups 4×6 (Pushup-hands land on plates-drop back to floor)`}</p>
    <p>{`Shoulder Press 4×6\\@70% 1RM`}</p>
    <p>{`DB Rows 4×6`}</p>
    <p>{`then, 3 Rounds for time of:`}</p>
    <p>{`50-Double Unders`}</p>
    <p>{`25-Parallel Bar Dips`}</p>
    <p>{`25-Ring Rows`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      